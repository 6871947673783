<template>
  <div class="containerMod">
    <div class="caricamento" v-if="loading > 0">
      <div class="">
        <div class="d-flex justify-center mb-4">
          <img src="/loading-3d.gif" />
          <!-- <video  loop autoplay>
            
            <source src="/loading-3d.mp4"  />
            Video non supportato
          </video> -->
        </div>
        <h4>Caricamento in corso dei modelli 3d...</h4>
      </div>
    </div>
    <v-btn class="mb-10" fixed bottom left>a</v-btn>
    <div @dblclick="ridimensionaCanvas()" class="canvas" ref="canvas" />
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { Filesystem } from "@capacitor/filesystem";
import { OrbitControls } from "@/loader/OrbitControls";
export default {
  data() {
    return {
      camera: null,
      light: null,
      lightHelper: null,
      light2: null,
      light3: null,
      lightHelper3: null,
      scene: null,
      renderer: null,
      controls: null,
      gridHelper: null,
      loader: null,

      posizionamentoScene: null,
      accessoriScene: null,
      posizionamentoSceneCaricati: [],
      accessoriSceneCaricati: [],

      loading: 0,

      accessoriCaricati: []
    };
  },
  props: {
    /**
     * Array di oggetti di tutti gli accessori con il modello
     * @type {Array}
     */
    accessori: {
      type: Array,
      required: true
    },
    /**
     * Array di id degli accessori della struttura
     * serve per controllare se la struttura ha l'accessorio o colorarlo di rosso
     * @type {Array}
     */
    accessoriPresenti: {
      type: Array,
      required: true
    }
  },

  methods: {
    controllaDisponibilitàAccessorio(idFirebase) {
      return this.accessoriPresenti.includes(idFirebase);
    },

    searchChild(obj, name) {
      if (obj.name === name) {
        return obj;
      }
      for (var i = 0; i < obj.children.length; i++) {
        var child = obj.children[i];
        var found = this.searchChild(child, name);
        if (found) {
          return found;
        }
      }
      return null;
    },
    bytearrayToBlob(b64Data) {
      const sliceSize = 512;
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "application/GLTF" });
      return blob;
    },
    async getPathLocalStorage(name) {
      //Carica ul modello dal local storage, il name è l'id di firebase
      const modello = await Filesystem.readFile({
        path: `acessori/${name}.gltf`,
        directory: "DOCUMENTS"
      });
      const urlModello = URL.createObjectURL(
        this.bytearrayToBlob(modello.data)
      );
      return urlModello;
    },
    cambiaModello(mod) {
      if (mod === "pos") {
        this.accessoriScene.visible = false;
        this.posizionamentoScene.visible = true;
      } else if (mod === "acc") {
        this.accessoriScene.visible = true;
        this.posizionamentoScene.visible = false;
      }
      //console.log("accessoriScene", this.accessoriScene);
      //console.log("posizionamentoScene", this.posizionamentoScene);
    },
    async caricaConfigurazione(configurazione, nome) {
      //if (nome === "posizionamentoScene") return;
      this.loading++;
      this[nome + "Caricati"] = configurazione;
      //this.accessoriCaricati = configurazione;
      // console.log("caricamento " + nome, configurazione);
      for (var i = 0; i < this[nome + "Caricati"].length; i++) {
        var parte = this[nome + "Caricati"][i];

        //Se non ha un parent, allora è il modello principale
        //Se ce l'ha, allora è un accessorio ed è già stato caricato dal modello principale
        if (!parte.parentName) {
          this[nome] = await this.caricaParte({
            name: parte.originalName,
            path: await this.getPathLocalStorage(parte.originalName),
            rotation: { x: 0, y: 0, z: 0 },
            missing: false,
            position: { x: 0, y: 0, z: 0 },
            parent: this.scene,
            hide: false
          });
        }

        //Trovo tutti i figli della parte
        const children = this[nome + "Caricati"].filter(
          (element) => element.parentName === parte.name
        );

        //Carico tutti i figli
        const parent = this.searchChild(this[nome], parte.name);
        for (var j = 0; j < children.length; j++) {
          var child = children[j];
          //console.log("child", child);
          const path = await this.getPathLocalStorage(child.originalName);
          const payload = {
            name: child.name,
            path,
            parent: parent,
            position: {
              x: child.position.x + (child.offset ? child.offset.x : 0),
              y: child.position.y + (child.offset ? child.offset.y : 0),
              z: child.position.z + (child.offset ? child.offset.z : 0)
            },
            rotation: child.rotation
              ? {
                  x: THREE.MathUtils.degToRad(child.rotation.x),
                  y: THREE.MathUtils.degToRad(child.rotation.y),
                  z: THREE.MathUtils.degToRad(child.rotation.z)
                }
              : { x: 0, y: 0, z: 0 },
            hide: child.hide,
            missing: !this.controllaDisponibilitàAccessorio(child.originalName) //child.missing,
          };
          await this.caricaParte(payload);
        }
      }
      //console.log("scene", this.scene);
      // console.log("caricata: ", nome);
      if (nome === "accessoriScene") {
        this.accessoriScene.visible = false;
      }
      this.ridimensionaCanvas();
      this.loading--;
      // console.log("loading", this.loading);
      if (this.loading === 0)
        this.$emit("click", true);
    },
    async caricaParte({
      name,
      path,
      rotation,
      missing,
      position,
      parent,
      hide
    }) {
      /*
      name: name assingned to the object
      path: path of the model (link)
      parent : parent object
      */

      if (!name || !path || !parent) return;
      var modello = await this.loader.loadAsync(path);
      var sceneModello = modello.scene.clone();
      sceneModello.name = name;
      if (rotation)
        sceneModello.rotation.set(rotation.x, rotation.y, rotation.z);
      if (position)
        sceneModello.position.set(position.x, position.y, position.z);
      if (hide) sceneModello.visible = false;
      if (missing)
        for (var i = 0; i < sceneModello.children.length; i++) {
          var child = sceneModello.children[i];
          //console.log("child",name, child);
          if (missing) {
            if (child.type === "Mesh") {
              if (child.material.color.r < 0.8) child.material.color.r += 0.2;
              if (child.material.color.g > 0.2) child.material.color.g -= 0.2;
              if (child.material.color.b > 0.2) child.material.color.b -= 0.2;
              child.material.opacity = 0.3;
              child.material.transparent = true;
            } else if (child.type === "Group") {
              child.children.forEach((element) => {
                if (element.material.color.r < 0.8)
                  element.material.color.r += 0.2;
                if (element.material.color.g > 0.2)
                  element.material.color.g -= 0.2;
                if (element.material.color.b > 0.2)
                  element.material.color.b -= 0.2;
                element.material.opacity = 0.3;
                element.material.transparent = true;
              });
            }
          }
          /* if (child.isMesh) {
            if (missing) {
              if (child.material.color.r < 0.8) child.material.color.r += 0.2;
              if (child.material.color.g > 0.2) child.material.color.g -= 0.2;
              if (child.material.color.b > 0.2) child.material.color.b -= 0.2;
              child.material.opacity = 0.3;
              child.material.transparent = true;
            } else {
              child.material.opacity = 1;
              child.material.transparent = false;
            }
          } */
        }
      parent.add(sceneModello);
      return sceneModello;
    },
    pulisci() {
      const daMantenere = [
        "HemisphereLight",
        "AmbientLight",
        "GridHelper",
        "DirectionalLight",
        "DirectionalLightHelper",
        "SpotLight"
      ];
      this.scene.children.forEach((element) => {
        if (!daMantenere.includes(element.type)) {
          this.scene.remove(element);
        }
      });
    },
    //Funzone per impostare la dimensione del canvas
    ridimensionaCanvas() {
      // console.log("ridimensionaCanvas");
      const canvas = this.$refs.canvas;
      if (canvas) {
        const altezza = canvas.clientHeight;
        const larghezza = canvas.clientWidth;
        setTimeout(() => {
          this.renderer.setSize(larghezza, altezza);
          this.camera.updateProjectionMatrix();
          this.camera.aspect = larghezza / altezza;
        }, 100);
      }
    },
    //Funzione per abilitare il rendering di three.js
    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    //Funzione per inizializzare three.js
    inizializza() {
      //Camera
      if (this.camera === null) {
        this.camera = new THREE.PerspectiveCamera(
          75,
          window.innerWidth / window.innerHeight,
          0.1,
          1000
        );
        this.camera.position.z = 5;
        this.camera.position.y = 5;
        this.camera.position.x = 5;
        this.camera.lookAt(0, 0, 0);
      }
      //Scene
      if (this.scene === null) this.scene = new THREE.Scene();
      //Light

      if (this.light === null) {
        this.light = new THREE.HemisphereLight(0x636363, 2);

        this.scene.add(this.light);
      }
      /*  if (this.lightHelper === null && process.env.NODE_ENV === "development") {
        //add a sphere in the same position as the light
        this.lightHelper = new THREE.DirectionalLightHelper(this.light, 1);
        this.scene.add(this.lightHelper);
      } */
      if (this.light2 === null) {
        //this.light2 = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.6);
        this.light2 = new THREE.AmbientLight(0x808080, 1.5);
        //this.light2 = new THREE.AmbientLight(0xffffff, 0.5);
        this.scene.add(this.light2);
      }
      if (this.light3 === null) {
        this.light3 = new THREE.SpotLight(0xffffff, 0.8);
        this.light3.position.set(0, 10, 0);
      }

      if (
        this.lightHelper3 === null &&
        process.env.NODE_ENV === "development"
      ) {
        //add a sphere in the same position as the light
        this.lightHelper3 = new THREE.SpotLightHelper(this.light3);
        this.scene.add(this.lightHelper3);
      }

      //Grid
      if (this.gridHelper === null) {
        this.gridHelper = new THREE.GridHelper(50, 20);
        this.scene.add(this.gridHelper);
      }
      if (this.renderer === null)
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setClearColor(0xffffff, 1); // Set the background color
      /* 
      if (process.env.NODE_ENV === "development")
        this.renderer.setClearColor(0xff0000, 1); // Set the background color
 */
      if (this.controls === null)
        this.controls = new OrbitControls(
          this.camera,
          this.renderer.domElement
        );
      this.controls.update();

      if (this.loader === null) this.loader = new GLTFLoader();
      this.$refs.canvas.appendChild(this.renderer.domElement);
    }
  },
  mounted() {
    this.inizializza();
    this.animate();
    this.ridimensionaCanvas();
    window.addEventListener("resize", this.ridimensionaCanvas);
  }
};
</script>

<style>
.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.containerMod {
  width: 100%;
  height: 100%;
  position: relative;
}
.caricamento {
  position: absolute;
  height: 100%;
  background-color: rgb(252, 252, 252);
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.caricamento video,
img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  background-color: transparent;
}
</style>
